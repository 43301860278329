import axios from "axios";

class UploadAdapter {
	constructor(loader) {
		this.loader = loader;
	}

	upload() {
		return this.loader.file
			.then(file => new Promise((resolve, reject) => {
				let form = new FormData();
				form.append("arquivo", file, file.name);
				this.abortController = new AbortController();
				this.request = axios.post(`api/midia/criar-url`, form, {
					signal: this.abortController.signal,
					onUploadProgress: evt => {
						this.loader.uploadTotal = evt.total;
						this.loader.uploaded = evt.loaded;
					}
				}).then(response => {
					resolve({
						default: response.data
					});
				}).catch(() => reject(`N�o foi poss�vel fazer o upload do arquivo: ${file.name}`));
			}));
	}

	abort() {
		if (this.abortController) {
			this.abortController.abort();
		}
	}
}

export default function CustomUploadAdapter(editor) {
	editor.plugins.get("FileRepository").createUploadAdapter = loader => new UploadAdapter(loader);
};