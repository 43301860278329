import { render, staticRenderFns } from "./TemplatesEmailPage.vue?vue&type=template&id=001895ec&scoped=true"
import script from "./TemplatesEmailPage.vue?vue&type=script&lang=js"
export * from "./TemplatesEmailPage.vue?vue&type=script&lang=js"
import style0 from "./TemplatesEmailPage.vue?vue&type=style&index=0&id=001895ec&prod&scoped=true&lang=css"
import style1 from "./TemplatesEmailPage.vue?vue&type=style&index=1&id=001895ec&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "001895ec",
  null
  
)

export default component.exports